import PropTypes from "prop-types"
import React from "react"
import * as Service from "../components/service/index"
import { graphql } from "gatsby"
import * as util from "./util"

class Content {
  constructor(data) {
    this.partners = data.allContentfulCapitalCardSpendPartner.edges || []
    this.events = data.allContentfulCapitalCardEvent.edges || []
    this.rewards =
      data.allContentfulReward.edges
        .map(({ node }) => node)
        .filter(reward => reward.spendPartner !== null) || []
    this.categories =
      data.allContentfulCategory.edges.map(({ node }) => node) || []
  }
}

const ServiceTemplate = props => {
  const content = new Content(props.data)

  const INITIAL_STATE =
    props.location.search == "?events"
      ? Service.UI_STATE_VIEW_EVENTS
      : Service.UI_STATE_VIEW_REWARDS

  const [viewState, setViewState] = React.useState(INITIAL_STATE)

  const rewardsGroupedBySpendPartner = content.rewards.reduce(
    util.sortRewardsBySpendPartner,
    {}
  )
  const partnersWithRewards = util.associateSpendPartnersWithRewards(
    rewardsGroupedBySpendPartner,
    content.partners
  )

  const viewStates = {
    [Service.UI_STATE_VIEW_EVENTS]: <Service.Events events={content.events} />,
    [Service.UI_STATE_VIEW_REWARDS]: (
      <Service.Rewards
        partners={partnersWithRewards}
        categories={content.categories}
      />
    ),
  }

  return <>{viewStates[viewState]}</>
}

ServiceTemplate.propTypes = {
  data: PropTypes.shape({
    allContentfulCapitalCardEvent: PropTypes.shape({
      edges: PropTypes.array,
    }),
    allContentfulCapitalCardSpendPartner: PropTypes.shape({
      edges: PropTypes.array,
    }),
    allContentfulCategory: PropTypes.shape({
      edges: PropTypes.array,
    }),
    allContentfulReward: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
}

export default ServiceTemplate

export const serviceQuery = graphql`
  query ServiceById($id: String!, $today: Date!) {
    allContentfulCapitalCardEvent(
      filter: {
        service: { elemMatch: { id: { eq: $id } } }
        when: { gt: $today }
      }
    ) {
      edges {
        node {
          description
          when
          pointsRequired
          arePointsRequired
          specialConditions
          meetingLocation {
            lon
            lat
          }
          meetingAddress
          eventUrl
          meetingDetails {
            childMarkdownRemark {
              html
            }
          }
          actualLocation {
            lon
            lat
          }
          eventAddress
          spendPartner {
            id
            name
            description {
              raw
            }
            logo {
              small: fixed(width: 32, height: 32) {
                base64
                width
                height
                src
                srcSet
              }
              medium: fixed(width: 64, height: 64) {
                base64
                width
                height
                src
                srcSet
              }
              large: fixed(width: 128, height: 128) {
                base64
                width
                height
                src
                srcSet
              }
            }
          }
        }
      }
    }

    allContentfulReward {
      edges {
        node {
          description
          furtherDescription
          pointsRequired
          spendPartner {
            id
          }
        }
      }
    }

    allContentfulRegion {
      edges {
        node {
          id 
          name
        }
      }
    }

    allContentfulCategory {
      edges {
        node {
          name
        }
      }
    }

    allContentfulCapitalCardSpendPartner(
      filter: { service: { elemMatch: { id: { eq: $id } } } }
    ) {
      edges {
        node {
          id
          name
          websiteUrl
          currentlyOpen
          description {
            raw
          }
          categories {
            name
          }
          picture {
            full: fluid {
              ...GatsbyContentfulFluid
            }
            fixed: fixed(width: 320) {
              ...GatsbyContentfulFixed
            }
            thumb: fixed(width: 128, height: 128) {
              base64
              width
              height
              src
              srcSet
            }
          }
          logo {
            fixed(width: 128, height: 128) {
              base64
              width
              height
              src
              srcSet
            }
          }
          service {
            id
            name
          }
        }
      }
    }

    contentfulService(id: { eq: $id }) {
      id
      name
      region {
        id
        name
      }
    }
  }
`
